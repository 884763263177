<template>
  <div class="main-content">
    <div class="bg-white header border-bottom">
      <div class="container-fluid">
        <div class="border-0 header-body">
          <div class="row align-items-end">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title">Add Vehicle</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <router-link to="/fleet/list/vehicle" class="mb-4 btn btn-light btn-sm">
        Go back
      </router-link>
      <div v-if="loading" class="d-flex justify-content-center align-items-center">
        <div class="spinner-border" role="status"></div>
      </div>
      <div class="row" v-else>
        <!--        TODO: Extract form into reusable component-->
        <div class="col-12 col-xl-8">
          <div class="card">
            <div class="card-header">
              <div>
                <h4 class="card-header-title">Add a New Vehicle</h4>
              </div>
            </div>
            <div class="card-body">
              <div class="alert alert-danger alert-dismissible fade show" role="alert" v-if="errorMessage">
                {{ errorMessage }}
              </div>
              <form @submit.prevent="addVehicle">
                <div class="form-group row">
                  <div class="col-12">
                    <label class="form-label">Vehicle Brand</label>
                    <input v-model.trim="vehicle.brand" required type="text" class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <label class="form-label">Vehicle Name</label>
                    <input v-model.trim="vehicle.name" required type="text" class="form-control" />
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12">
                    <div class="tool">
                      <label class="form-label">Vehicle Plate Number <img src="@/assets/img/icons/question.svg" /></label>
                      <span class="tooltext2">example: ABC-123XY</span>
                    </div>
                    <input v-model.trim="vehicle.registration_number" required type="text" class="form-control" />
                    <p class="text-danger">{{ errorMessage }}</p>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12 col-md-6">
                    <label class="form-label">Vehicle Capacity</label>
                    <input v-model.trim="vehicle.seats" required type="number" class="form-control" />
                  </div>
                  <div class="col-12 col-md-6">
                    <label class="form-label">Vehicle Type</label>
                    <select v-model="vehicle.type" class="form-control form-select">
                      <option :value="null">Select</option>
                      <option v-for="(option, index) in vehicleTypes" :value="option.name" :key="index">{{ option.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-12 col-md-6">
                    <label class="form-label">Vehicle Code</label>
                    <input v-model.trim="vehicle.busCode" type="text" class="form-control" min="1" max="6" />
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-group">
                      <label class="form-label">Vehicle partner</label>
                      <v-select v-model="vehicle.partner_id" class="form-group" :options="vehiclePartners"
                        :reduce="(partner) => partner.id" label="company_name" required>
                      </v-select>
                    </div>
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-6">
                    <label class="form-label">Vehicle Amenities</label>
                    <input v-model.trim="vehicle.amenities" type="text" class="form-control" min="1" max="6" />
                  </div>
                  <div class="col-6">
                    <label class="form-label">Vehicle inventory Type</label> 
                      <select v-model="vehicle.inventory_type" class="form-control form-select">
                      <option :value="null">Select</option>
                      <option v-for="(option, index) in ['regular', 'charter', 'one-off' ]" :value="option" :key="index">{{ option }}
                      </option>
                    </select>
                  </div>
                </div>

                <hr class="my-4" />

                <div class="form-group">
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" v-model="shouldCreateTrackingDetails" />
                    <label class="form-check-label">
                      Add Tracking Details
                    </label>
                  </div>
                </div>

                <div class="row" v-if="shouldCreateTrackingDetails">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="form-label">Tracking ID</label>
                      <input v-model.trim="trackerOption.tracking_id" type="text" class="form-control"
                        :required="shouldCreateTrackingDetails" />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Provider Name</label>
                      <input v-model.trim="trackerOption.provider_name" type="text" class="form-control"
                        :required="shouldCreateTrackingDetails" />
                    </div>
                    <div class="form-group">
                      <label class="form-label">Provider Description</label>
                      <textarea v-model.trim="trackerOption.provider_description" type="text" class="form-control"
                        :required="shouldCreateTrackingDetails"></textarea>
                    </div>
                  </div>
                </div>

                <button type="submit" class="mt-4 btn btn-primary" :disabled="processing">
                  {{ processing ? 'Processing...' : 'Add Vehicle' }}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'
import Swal from 'sweetalert2'
import {axiosInstance as axios} from '@/plugins/axios'
import {useRouter} from 'vue-router/composables'
const props = defineProps(['vehicleId'])
const router = useRouter()

const vehicle = ref({
  brand: '',
  name: '',
  registration_number: '',
  seats: '',
  type: '',
  busCode: '',
  amenities: '',
  partner_id: null,
  inventory_type: 'regular'
})
const trackerOption = ref({
  tracking_id: '',
  provider_name: '',
  provider_description: ''
})
const processing = ref(false)
const errorProcessing = ref(false)
const errorMessage = ref('')
const loading = ref(false)
const errorLoading = ref(false)
const shouldCreateTrackingDetails = ref(false)
const vehicleTypes = ref([])
const vehiclePartners = ref([])

const isVehicleTrackingDetailsComplete = computed(() => {
    return (
      trackerOption.value.tracking_id !== '' &&
      trackerOption.value.provider_name !== '' &&
      trackerOption.value.provider_description !== ''
    )
  })


const fetchVehicleTypes = () => {
    axios.get('/v1/vehicle-types').then((res) => {
      vehicleTypes.value = res.data.data
    })
  }
const fetchVehiclePartners = () => {
    axios.get('/v1/partners?metadata=true&page=1&fields=id,company_name').then((res) => {
      vehiclePartners.value = res.data.data
    })
  }
const addVehicle = () => {
    processing.value = true

    errorMessage.value = ''

    const checkPlateNumberFormat = /^[A-Za-z]{2,3}-[0-9]{2,3}[A-Za-z]{2,3}$/gi

    const plate_number = checkPlateNumberFormat.test(vehicle.value.registration_number)

    if (plate_number == true) {
      axios
        .post('/v1/vehicles/', {
          brand: vehicle.value.brand,
          name: vehicle.value.name,
          type: vehicle.value.type,
          seats: vehicle.value.seats,
          registration_number: vehicle.value.registration_number,
          amenities: vehicle.value.amenities,
          code: vehicle.value.busCode ? vehicle.value.busCode : null,
          inventory_type: vehicle.value.inventory_type,
        })
        .then(async (res) => {
          if (
            shouldCreateTrackingDetails.value &&
            isVehicleTrackingDetailsComplete.value
          ) {
            await addVehicleTrackingDetails(res.data.id)
          }

          Swal.fire({
            icon: 'success',
            title: 'Vehicle Added',
            text: 'Vehicle has been added successfully',
            showCloseButton: true
          })

          router.push({
            name: 'ShowVehicle',
            params: { vehicleId: res.data.id }
          })
        })
        .catch((e) => {
          Swal.fire({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          errorMessage.value = msg
        })
        .finally(() => (processing.value = false))
    } else {
      errorMessage.value = 'The Plate Number Entered Is Not Valid'
    }
  }
 const addVehicleTrackingDetails = async (vehicleId) => {
    await axios.put(`/v1/vehicles/${props.vehicleId}/tracking`, {
      tracking_id: trackerOption.value.tracking_id,
      provider_name: trackerOption.value.provider_name,
      provider_description: trackerOption.value.provider_description
    })
  }



fetchVehicleTypes()
fetchVehiclePartners()

</script>

<style lang="scss" scoped>
@use "src/assets/scss/partials/overrides/v-select";
@import "@/assets/scss/partials/tooltip";
</style>
